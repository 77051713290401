import React from "react";
import styled from "styled-components";
import { PortableText } from "@portabletext/react";

import { Caption, Paragraph } from "../../ui/Typography";
import Img from "./Img";
import Video from "./Video";
import components from "./Components";

const Wrapper = styled.div`
  grid-column: 1 / -1;
  width: 100%;
  padding: 0 0 10px 0;

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: 0 0 5px 0;
  }
`;

export default function Section({ value }) {
  return (
    <Wrapper padding="0 0 10px 0">
      <>{value.title && <Caption>{value.title}</Caption>}</>
      <>
        {value.info && (
          <PortableText value={value.info} components={components} />
        )}
      </>
      <>
        {value.media &&
          value.media.map((media) => {
            if (media._type === "img") {
              return <Img value={media} key={media._key} />;
            } else if (media._type === "video") {
              return <Video value={media} key={media._key} />;
            }
          })}
      </>
    </Wrapper>
  );
}
