import React from "react";
import { Link } from "gatsby";

export default function Href({ value, children }) {
  if (value?.blank || (value?.href || "").startsWith("http")) {
    return (
      <a
        href={value?.href}
        target={value.blank ? "_blank" : "_parent"}
        rel={"noopener noreferrer"}
      >
        {children}
      </a>
    );
  } else {
    return (
      <Link to={`/work/${value?.reference?.slug?.current}`}>{children}</Link>
    );
  }
}
