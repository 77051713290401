import React from "react";
import { graphql } from "gatsby";
import { PortableText } from "@portabletext/react";

import Transition from "../components/Transition";

import Title from "../components/Project/Title";
import Meta from "../components/Project/Meta";
import component from "../components/Project/Components";
import Buttons from "../components/Project/Buttons";
import { SEO } from "../components/SEO";

const ProjectTemplate = ({ pageContext, data }) => {
  const project = data.project;
  return (
    <Transition>
      <>{project.title && <Title title={project.title} />}</>
      <>
        {project.client && project._rawProjectCategory && (
          <Meta
            client={project.client}
            category={project._rawProjectCategory}
          />
        )}
      </>
      <>
        {project._rawContent && (
          <PortableText value={project._rawContent} components={component} />
        )}
      </>
      <Buttons context={pageContext} />
    </Transition>
  );
};

export const query = graphql`
  query Project($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      title
      slug {
        current
      }
      id
      client
      mainMedia {
        ... on SanityImg {
          asset {
            url
          }
        }
        ... on SanityVideo {
          vimeoURL
        }
      }
      _rawProjectCategory(resolveReferences: { maxDepth: 10 })
      _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`;

export default ProjectTemplate;

export const Head = ({ data }) => (
  <SEO
    title={`${data.project.title} | Pal.tv`}
    asset={
      data.project.mainMedia[0]?.asset?.url ||
      data.project.mainMedia[0]?.vimeoURL
    }
  />
);
