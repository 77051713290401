import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

import { Caption } from "../../ui/Typography";

const Wrapper = styled.div`
  grid-column: 1 / -1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
`;

export default function Video({ value }) {
  return (
    <Wrapper>
      <ReactPlayer
        url={value.vimeoURL}
        playing={value.autoplay}
        loop={value.loop}
        muted={value.muted}
        controls={value.controls}
        playsinline={value.autoplay}
        pip={false}
        width={"100%"}
        height={"auto"}
        config={{
          vimeo: {
            playerOptions: {
              byline: false,
              portrait: false,
              title: false,
              responsive: true,
              color: "EB3329",
            },
          },
        }}
      />
      {value.caption && <Caption padding="0">{value.caption}</Caption>}
    </Wrapper>
  );
}
