import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { Button } from "../../ui/Button";
import { useIsMobile } from "../../hooks/useMediaQuery";

const ButtonsWrapper = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 10px 0;

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: 5px 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
    a {
      &:nth-child(1) {
        grid-column: 1 / span 2;
      }
      &:nth-last-child(1) {
        grid-column: 3 / span 4;
      }
    }
  }
`;

const Buttons = ({ context }) => {
  const isMobile = useIsMobile();
  return (
    <ButtonsWrapper>
      <>
        {context?.prev !== undefined ? (
          <Link to={`/work/${context?.prev}`}>
            <Button disabled={context?.prev === undefined}>
              {isMobile ? `Prev` : `Previous`}
            </Button>
          </Link>
        ) : (
          <>
            <Button disabled={context?.prev === undefined}>
              {isMobile ? `Prev` : `Previous`}
            </Button>
          </>
        )}
      </>
      <>
        {context?.next !== undefined ? (
          <Link to={`/work/${context?.next}`}>
            <Button disabled={context?.next === undefined}>Next</Button>
          </Link>
        ) : (
          <>
            <Button disabled={context?.next === undefined}>Next</Button>
          </>
        )}
      </>
    </ButtonsWrapper>
  );
};

export default Buttons;
