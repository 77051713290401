import React from "react";
import styled from "styled-components";

import { Heading } from "../../ui/Typography";

const Wrapper = styled.div`
  grid-column: 1 / -1;
  width: 100%;
  padding: 0px 0px 40px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.color};

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: 0px 0px 20px 0;
  }
`;

export default function Title({ title }) {
  return (
    <Wrapper>
      <Heading>{title}</Heading>
    </Wrapper>
  );
}
