import React from "react";
import Section from "./Section.js";
import Img from "./Img.js";
import Video from "./Video.js";
import Href from "./Href.js";
import Paragraph from "./Paragraph.js";

const components = {
  types: {
    section: Section,
    img: Img,
    video: Video,
  },
  marks: {
    link: Href,
  },
  block: {
    normal: Paragraph,
  },
};

export default components;
