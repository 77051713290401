import React from "react";
import styled from "styled-components";

import { Paragraph } from "../../ui/Typography";

const MetaWrapper = styled.div`
  grid-column: 1 / -1;
  padding: 5px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.color};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
`;
const Metadata = styled.div`
  padding-right: 90px;
  &:nth-child(1) {
    border-right: 1px solid ${(props) => props.theme.colors.color};
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: 5px 0;
    &:nth-child(1) {
      padding-right: 15px;
    }
    gap: 15px;
    width: auto;
  }
`;

// const Underline = styled.span`
//   font-family: ${(props) => props.theme.fonts.mono};
//   font-size: 15px;
//   line-height: 1.265;
//   text-decoration: underline;

//   @media ${(props) => props.theme.breakpoints.mobile} {
//     font-size: 12px;
//     line-height: 15px;
//   }
// `;

export default function Meta({ client, category }) {
  return (
    <MetaWrapper>
      <Metadata>
        <Paragraph>Client:</Paragraph>
        <Paragraph>{client}</Paragraph>
      </Metadata>
      <Metadata>
        <Paragraph>Expertise:</Paragraph>
        <Paragraph>{category.map((tag) => tag.title).join(", ")}</Paragraph>
      </Metadata>
    </MetaWrapper>
  );
}
